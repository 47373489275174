@if (showAsBackground) {
  <div
    class="searchimage"
    title="{{alt}}"
    [ngStyle]="{'background-image': 'url(' + (getUrl() || missingImageSrc) + ')'}"
  ></div>
} @else {
  <img
    src="{{getUrl() || missingImageSrc}}"
    class="profile-image img-responsive"
    [ngClass]="{'rounded-circle': roundedImg, 'fit-image': isCard}"
    alt="{{alt}}">
}

